/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */
import { isTablet } from "./helper";
import { getScreenHeight } from "./helper.js";
import CoreAnimation from "./animations/CoreAnimation";

export const toggleMenu = () => {
  let button = document.getElementById("toggle"),
    container = document.getElementById("site-navigation"),
    html = document.querySelector("html");

  if (button && container) {
    button.addEventListener("click", () => {
      CoreAnimation.slideToggle(
        document.querySelector("#site-navigation > div > ul")
      );

      if (button.classList.contains("open")) {
        button.classList.remove("open");
        html.style.overflowY = "auto";
        return;
      }

      button.classList.add("open");

      if (getScreenHeight() <= 1260) {
        html.style.overflowY = "hidden";
      } else {
        html.style.overflowY = "auto";
      }
    });
  }
};

export const closeMenu = () => {
  let links = document.querySelectorAll(".main-navigation a"),
    nav = document.getElementById("site-navigation"),
    toggle = document.getElementById("toggle");

  links.forEach(e => {
    e.addEventListener("click", () => {
      nav.classList.remove("open");
      toggle.classList.remove("open");
    });
  });
};

export const addSticky = () => {
  let toggle = document.getElementById("toggle"),
    header = document.getElementById("masthead");

  toggle.addEventListener("click", () => {
    header.classList.add("sticky");
  });
};

const links = document.getElementsByClassName("menu-item");
const menu = document.getElementById("menu-header");
const toggle = document.getElementById("toggle");
const html = document.querySelector("html");

for (let i = 0; i < links.length; i++) {
  links[i].onclick = () => {
    if(isTablet()) {
      menu.style.display = "none";
      toggle.classList.remove("open");
      html.style.overflowY = "auto";
    }
  };
}
