const modal = document.getElementById("popup");
const person = document.getElementsByClassName("person");
const span = document.getElementsByClassName("close")[0];
const content = document.getElementById("modal-content");

for (let i = 0; i < person.length; i++) {
  person[i].onclick = () => {
    const description = person[i].getAttribute("data-description");
    const url = person[i].getAttribute("data-url");
    const page = person[i].getAttribute("data-page");
    const image = person[i].getAttribute("data-image");
    const contentToPush = `<img src='${image}'><p>${description}</p><a href="${url}" target="_blank">${page}</a>`;
    content.innerHTML = contentToPush;
    modal.style.top = "0";
  };
}

span.onclick = function() {
  modal.style.top = "-100vh";
};

window.onclick = function(event) {
  if (event.target === modal) {
    modal.style.top = "-100vh";
  }
};
