import { isMobile, isTablet } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";

window.addEventListener("load", () => {
  new SiteHeader();
  new FloatLabels();

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});
const menu = document.getElementById("menu-header");
const toggle = document.getElementById("toggle");
const html = document.querySelector("html");

window.onresize = () => {
  if (isTablet()) {
    menu.style.display = "none";
    toggle.classList.remove("open");
    html.style.overflowY = "auto";
    closeMenu();
    addSticky();
  } else {
    closeMenu();
    toggle.classList.remove("open");
    html.style.overflowY = "auto";
    menu.style.display = "flex";
  }
};