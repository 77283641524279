// https://github.com/kenwheeler/slick

import $ from "jquery";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

/****** SLIDERS *******/
const sliders = {
  clients: $("#slider-clients")
};

sliders.clients.slick({
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
  dots: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  rows: 2,
  slidesToScroll: 2,
  slidesToShow: 6,
  speed: 700,
  responsive: [
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 6
      }
    },
    {
      breakpoint: 760,
      settings: {
        slidesToShow: 4
      }
    },
    {
      breakpoint: 760,
      settings: {
        slidesToShow: 2
      }
    }
  ]
});
